import 'bootstrap-select';

export function initScheduleDate(): void {
  $(document).off('click', '.schedule-button').on('click', '.schedule-button', e => {
    const $btnEle = $(e.currentTarget);
    const emailChainId = $(e.currentTarget).data('email-chain-id');
    const $toggledEle = $('.schedule-' + emailChainId);

    if($toggledEle.is(':visible')) {
      $btnEle.removeClass('active');
    } else {
      $btnEle.addClass('active');
    }

    $toggledEle.toggle('fast');
  });

  $('.schedule-date').datepicker({
    format: "yyyy-mm-dd",
    clearBtn: true,
    autoclose: true,
    startDate: new Date(),
    todayHighlight: true
  })

  $(document).on("ajax:success", ".schedule-contact", function (event) {
    let emaiChainId = event.target.id.replace("edit_email_chain_", "")
    let $toggledEle = $(".schedule-" + emaiChainId);
    let $btnEle = $(".schedule-button-" + emaiChainId);
    let selectedDate = $(".schedule-date").val();
    $toggledEle.hide()

    if(selectedDate) {
      $btnEle.removeClass('active').addClass('created');
      $btnEle.attr('title', `Follow-up scheduled for ${selectedDate}`);
    } else {
      $btnEle.removeClass('active').removeClass('created');
      $btnEle.attr('title', "Schedule follow-up");
    }
  });
}