// @format

import 'bootstrap';
import 'bootstrap-select';

export function initSelectpicker(): void {
  destroySelectpicker();
  $('.selectpicker').selectpicker();
  $('.selectpickerr').selectpicker();

  window.addEventListener('pageshow', (event) => {
    if (event.persisted) {
      destroySelectpicker();
      $('.selectpicker').selectpicker();
    }
  });
}

export function destroySelectpicker(): void {
  $('.selectpicker').selectpicker('destroy');
  $('.selectpickerr').selectpicker('destroy');
}
