export function initQlfrModal(): void {
  $(".qlfr-show-modal").click(function(event) {
    event.preventDefault();
    event.stopImmediatePropagation();

    $(".qlfr-modal-container").addClass('active')
  });

  $(".submit-form-button").on("click", function(e) {
    $("#bot-setting-form").submit();
  })

  $(".qlfr-show-ai-sequence-modal").click(function(event) {
    event.preventDefault();
    event.stopImmediatePropagation();

    $(".qlfr-modal-container.ai-sequence-generator").addClass('active')
  });

  $(".qlfr-show-the-modal").on('click', function(event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    console.log( $(this).data('modal'))
    $("#"+ $(this).data('modal')).addClass('active')
  });

  $(".qlfr-show-closest-modal").on('click', function(event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    $(this).parent().find('.qlfr-modal-container').addClass('active')
    sendTitleListener(event.currentTarget);
  });

  $(".qlfr-modal-container").click(function(event) {
    if ($(event.target).is(".qlfr-modal-container")) {
      closeBotModal();
    }
  });

  $(".qlfr-modal-close").click(function(event) {
    closeBotModal();
  });

  $(document).on('ajax:success', '.upload-to-jobylon-form', event => {
    closeBotModal();
    $(event.target).parents(".prospect").find(".push-to-jobylon").replaceWith("<i class='fal fa-external-link'></i> Uploaded to Jobylon")
  });

  $(document).on('ajax:error', '.upload-to-jobylon-form', event => {
    alert("There was an error pushing to Jobylon")
    closeBotModal();
  });
}

function closeBotModal(): void {
  $(".qlfr-modal-container").removeClass('active')
}

const sendTitleListener = (currentTarget) => {
  $(".send-title").on('click',(e) => {
    e.preventDefault();
    const form = $(e.currentTarget.closest('form'))
    const selected = form.find('.selectpickerr option:selected')
    const gender = selected.val()
    const display = selected.text()
    $.ajax({
      url: `/bots_prospect/${form.data('id')}/${gender}`,
      data: {
        authenticity_token: $('[name="csrf-token"]').attr('content'),
      },
      type: 'PATCH',
      success: function() {
        closeBotModal();
        $(currentTarget).text(`[${display}]`);
      },
    });
  })
}

export function initAiSequenceModal() {
  initTabs();
  initUploadFileInput();
}

function initTabs() {
  const $inputTab = $('.ai-sequence-generator .ai-tab.input')
  const $uploadTab = $('.ai-sequence-generator .ai-tab.upload')
  const $linkTab = $('.ai-sequence-generator .ai-tab.link')
  const $inputForm = $('.ai-sequence-generator .input-form')
  const $jobUploader = $('.ai-sequence-generator .upload-job-ad')
  const $jobLink = $('.ai-sequence-generator .link-job-ad')

  $inputTab.on('click', function(event) {
    $inputTab.addClass('active');
    $uploadTab.removeClass('active')
    $linkTab.removeClass('active')

    $inputForm.removeClass('d-none')
    $jobUploader.addClass('d-none')
    $jobLink.addClass('d-none')
  });

  $uploadTab.on('click', function(event) {
    $uploadTab.addClass('active');
    $inputTab.removeClass('active')
    $linkTab.removeClass('active')

    $inputForm.addClass('d-none')
    $jobUploader.removeClass('d-none')
    $jobLink.addClass('d-none')
  });

  $linkTab.on('click', function(event) {
    $linkTab.addClass('active');
    $inputTab.removeClass('active')
    $uploadTab.removeClass('active')

    $inputForm.addClass('d-none')
    $jobUploader.addClass('d-none')
    $jobLink.removeClass('d-none')
  });
}

function initUploadFileInput() {
  const $fileInput = $('input[type="file"]');

  if ($fileInput.length) {
    $fileInput.on('change', function (this: HTMLInputElement) {
      const maxSize = parseInt($(this).data('maxSize') as string, 10);
      const file = this.files?.[0];

      if (file && file.size > maxSize) {
        alert('File size must be less than 5MB.');
        $(this).val('');
      }
    });
  }
}
