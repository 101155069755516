export function initNotes(): void {
  $(document).off('click', '.notes-button').on('click', '.notes-button', e => {
    const $btnEle = $(e.currentTarget);
    const botsProspectId = $(e.currentTarget).data('bots-prospect-id');
    const $toggledEle = $('.notes-' + botsProspectId)
    
    if($toggledEle.is(':visible')) {
      $btnEle.removeClass('active');
    } else {
      $btnEle.addClass('active');
    }

    $toggledEle.toggle('fast');
  });

  $(document).on("ajax:success", ".write-note-form", event => {
    const value = event.target.querySelector('textarea[name="bots_prospect[notes]"]').value;
    const botsProspectId = event.target.id.replace("edit_bots_prospect_", "")
    $(".notes-" + botsProspectId).hide()

    if(value) {
      $(".notes-button-" + botsProspectId).removeClass('active').addClass('created');
    } else {
      $(".notes-button-" + botsProspectId).removeClass('active').removeClass('created');
    }
  })
}
